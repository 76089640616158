@import "~bootswatch/dist/flatly/variables";
$input-color: $gray-800;
$enable-negative-margins: true;
@import "~bootstrap/scss/bootstrap";
@import "~bootswatch/dist/flatly/bootswatch";

nav.navbar {
  border-bottom: 2px solid #8ed122 !important;
}

.scanner-marker {
  width: 25px;
  line-height: 22px;
  font-size: 12px;
  vertical-align: middle;
  border-radius: 5px;
  position: relative;
}

.scanner-number {
  color: white;
  display: inline-block;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  text-align: center;
  vertical-align: middle;
  pointer-events: none;
}

.scanner-icon {
  margin-left: -5px;
  display: inline-block;
  position: absolute;
}

.scanner-popup pre {
  margin: 0;
  max-height: 250px;
  min-width: 300px;
  overflow-y: auto;
  overflow-x: auto;
}

.scanner-popup .list-group-item {
  padding: 0.2rem;
}

.scanner-popup p {
  margin-bottom: 0.5rem;
}

.sticky-top-pad {
  position: sticky;
  top: 8px;
  z-index: 1020;
}

@media (min-width: 1200px) {
  .sticky-xl-top-pad {
    position: sticky;
    top: 8px;
    z-index: 1020;
  }
}

////// Styling for `InstantSelector` scrubber //////

// Default scrubber and thumb are very narrow, we make them thicker, and make the colours
// more muted, to match the rest of the page.
.bar {
  height: 7px !important;
  border-radius: 5px;
}

.bar__progress {
  border-radius: 5px;
}

.bar__thumb {
  height: 15px !important;
  width: 15px !important;
  background-color: #536988 !important; // Same hue as flatly 'primary'.
}

.bar:hover {
  height: 8px !important;
}

.bar__thumb:hover {
  height: 20px !important;
  width: 20px !important;
}

.scrubber.active .bar__progress {
  background-color: #536988 !important;
}

// Scrubber is grey when inactive.
.scrubber.inactive .bar * {
  background-color: #cbcbcb !important;
}
////// End styling for scrubber //////
